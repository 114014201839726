<template>
  <div class="horizontal-padding feed">
    <post-list />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PostList from '@/components/posts/PostList.vue';

export default defineComponent({
  components: {
    PostList,
  },
});
</script>

<style lang="scss" scoped>
  .feed {
    margin: 0.2rem auto 0;
    max-width: 500px;
  }
</style>
